<template>
    <div style="padding: 10px">
        <!--功能区域-->
        <div style="margin: 10px 0">
<!--            <el-button type="primary" @click="add_task">新增任务</el-button>-->
            <el-input v-model="search" placeholder="请输入电话号码进行查询" style="width: 300px; margin-left: 800px" clearable></el-input>
            <el-button type="primary" style="margin-left: 5px" :loading="page_loading" @click="load">查询</el-button>
        </div>
        <el-table
                :data="tableData"
                v-loading="page_loading"
                border
                stripe
                row-key="id"
                lazy
                default-expand-all
                :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
                style="width: 100%">

            <el-table-column
                    prop="caller.user_name"
                    label="员工姓名">
            </el-table-column>
            <el-table-column
                    prop="caller.team.team_name"
                    label="所属团队">
            </el-table-column>
            <el-table-column
                    prop="client.client_num"
                    label="客户号码">
            </el-table-column>
            <el-table-column
                    prop="client.client_num_area"
                    label="号码归属地">
            </el-table-column>
            <el-table-column label="备注">

                <template #default="scope">

                    {{remarkFilter(scope.row.call_remark)}}
                    <a class="el-link el-link--primary is-underline" style="font-size: 12px;"
                       v-if="isRemarkViewMore(scope.row.call_remark)"
                        @click="viewMoreRemark(scope.row.call_remark)">
                        <span class="el-link--inner">查看更多...</span>
                    </a>
                </template>
            </el-table-column>

            <el-table-column label="操作" width="260">
                <template #default="scope">
                    <el-button size="mini" type="primary" plain @click="handleEdit(scope.row)">编辑</el-button>
                    <el-popconfirm title="删除后需要重新派发，确定删除吗？" @confirm="handleDelete(scope.row)"
                                   v-if="User.roles[0].id<4">
                        <template #reference>
                            <el-button size="mini" type="danger" :loading="scope.row.deleteBtnLoading">删除</el-button>
                        </template>
                    </el-popconfirm>
                    <el-button v-if="scope.row.caller.user_id==User.user_id" size="mini" type="primary" plain
                               @click="calldel(scope.row)" :loading="scope.row.callBtnLoading">拨号</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div style="margin: 10px 0">
            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="currentPage"
                    :page-sizes="[5, 10, 20]"
                    :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total">
            </el-pagination>


            <!--新增弹窗-->
            <el-dialog title="新增任务" v-model="dialogVisible" width="30%">
                <el-form model="calldata_form" label-width="120px">
                    <el-form-item label="电话号码">
                        <el-input v-model="calldata_form.client.client_num" style="width: 75%" placeholder="请输入手机号"></el-input>
                    </el-form-item>
                    <el-form-item label="客户姓名">
                        <el-input v-model="calldata_form.client.client_name" style="width: 75%" placeholder="请输入姓名"></el-input>
                    </el-form-item>
                    <!--下拉选择话务员-->
                    <el-form-item label="话务员">
                        <el-select v-model="calldata_form.caller" placeholder="请选择话务员" style="width: 75%">
                            <el-option
                                    v-for="(item,index) in caller_info"
                                    :key="item.user_id"
                                    :label=" '('+item.user_id + ')' +  item.user_name "
                                    :value="index">
                                <span style="float: left">{{ item.user_id }}</span>
                                <span style="float: right; color: var(--el-text-color-secondary);">{{ item.user_name }}</span>
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="备注">
                        <el-input type="textarea" v-model="calldata_form.call_remark" style="width: 75%" placeholder="请输入备注"></el-input>
                    </el-form-item>
                </el-form>
                <template #footer>
                  <span class="dialog-footer">
                    <el-button @click="dialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="add_save">确 定</el-button>
                  </span>
                </template>
            </el-dialog>
            <!--显示更多备注-->
            <el-dialog title="备注" v-model="moreRemarkFormVisible" width="30%"  :before-close="closeMoreRemarkForm">
                <el-input type="textarea" rows="15" readonly v-model="moreRemarkText" placeholder="请输入备注"></el-input>
                <template #footer>
              <span class="dialog-footer">
                <el-button type="primary" @click="closeMoreRemarkForm">关闭</el-button>
              </span>
                </template>
            </el-dialog>



            <!--编辑弹窗-->
            <el-dialog title="编辑任务" v-model="EditVisible" width="30%">
                <el-form model="Edit_form" label-width="120px">
                    <el-form-item label="电话号码">
                        <el-input v-model="Edit_form.client.client_num" style="width: 75%" placeholder="请输入手机号"></el-input>
                    </el-form-item>
                    <el-form-item label="客户姓名">
                        <el-input v-model="Edit_form.client.client_name" style="width: 75%" placeholder="请输入姓名"></el-input>
                    </el-form-item>
                    <el-form-item label="话务员账号">
                        <el-input v-model="Edit_form.caller.user_num" style="width: 75%" disabled></el-input>
                    </el-form-item>

                    <el-form-item label="话务员名称">
                        <el-input v-model="Edit_form.caller.user_name" style="width: 75%"  disabled></el-input>
                    </el-form-item>

                    <el-form-item label="备注">
                        <el-input type="textarea" rows="6" v-model="Edit_form.call_remark" style="width: 75%" placeholder="请输入备注"></el-input>
                    </el-form-item>
                </el-form>
                <template #footer>
                  <span class="dialog-footer">
                    <el-button @click="EditVisible = false">取 消</el-button>
                    <el-button type="primary" @click="Edit_task" :loading="editOKBtnLoading">确 定</el-button>
                  </span>
                </template>
            </el-dialog>





        </div>
    </div>

</template>

<script>
    // @ is an alias to /src


    import request from "../../utils/request";

    export default {
        name: 'tasklist',
        data(){
            return{
                calldata_form:{

                },
                Edit_form:{

                },

                dialogVisible:false,//新增弹窗   默认是关闭的
                EditVisible:false,//编辑弹窗    默认是关闭的
                editOKBtnLoading:false,
                search:'',
                total: 1,
                page_loading:false,
                currentPage: 1,
                pageSize:10,
                tableData: [],
                moreRemarkFormVisible:false,
                moreRemarkText:'',
                User:{},//用于获取当前用户信息
                caller_info:[],//新增任务的时候选择话务员使用
            }
        },
        created() {
            this.load()
        },
        methods:{
            load() {
                this.page_loading = true
                let userJSON = sessionStorage.getItem("user")
                this.User=JSON.parse(userJSON)
                request.post("/tasklist"+'?pageNum='+this.currentPage+'&pageSize='+this.pageSize+'&search='+this.search).then(res => {
					if(res.code === 200){
						this.tableData = res.data.list //接收到的数据
						this.total = res.data.total
					}
					else{
					    this.$message({
					        type:"error",
					        message:res.msg,
					    })
					}
                    this.page_loading = false
                }).catch(e=>{
                    this.page_loading = false
                    this.$message({
                        type:"error",
                        message:'查询失败,服务器错误',
                    })
                })

            },
            remarkFilter(remark){
                if(remark&&remark.length>30){
                    return remark.substring(0,30);
                }else {
                    return remark;
                }
            },
            isRemarkViewMore(remark){
                if(remark&&remark.length>30){
                    return true;
                }else {
                    return false;
                }
            },
            viewMoreRemark(remark){
                this.moreRemarkText=remark
                this.moreRemarkFormVisible=true

            },
            closeMoreRemarkForm(){
                this.moreRemarkFormVisible=false
            },
            add_task(){//新增任务的时候  请求到所有话务员的名字和账号
                this.dialogVisible=true;
                request.post("/user/list").then(res => {
                    if(res.code === 200){
                        this.caller_info = res.data//所有话务员的信息存在info里  用于下拉框选择
                    }else{
                        this.$message({
                            type: "error",
                            message: res.msg
                        })
                    }
                })
                this.calldata_form.caller={}
                this.calldata_form.client={}
            },
            add_save(){
                //新增任务
                this.calldata_form['caller']=this.caller_info[this.calldata_form['caller']]
                request.post("/tasklist/add",this.calldata_form).then(res =>{
                    if(res.code === 200){
                        this.$message({
                            type:"success",
                            message:"新增成功"
                        })
                    }
                    else{
                        this.$message({
                            type:"error",
                            message:res.msg,
                        })
                    }
                    this.load() // 刷新表格的数据
                })
                this.dialogVisible = false  // 关闭弹窗

            },
            Edit_task(){
                this.editOKBtnLoading=true
                request.post("/tasklist/change",this.Edit_form).then(res =>{
                    if(res.code === 200){
                        this.$message({
                            type:"success",
                            message:"编辑成功"
                        })
                        this.EditVisible = false  // 关闭弹窗
                        this.load() // 刷新表格的数据
                    }
                    else{
                        this.$message({
                            type:"error",
                            message:res.msg,
                        })
                    }
                    this.editOKBtnLoading=false
                }).catch(e=>{
                    this.editOKBtnLoading=false
                    this.$message({
                        type:"error",
                        message:'请求失败,服务器错误',
                    })
                })


            },
            handleEdit(row){ //编辑按钮被点击  弹出编辑表单
                this.Edit_form = JSON.parse(JSON.stringify(row))
                this.EditVisible = true
            },
            handleDelete(row){
                var call_id=row.call_id
                row.deleteBtnLoading=true
                request.post("/tasklist/delete/" + call_id).then(res =>{
                    if(res.code === 200){
                        this.$message({
                            type:"success",
                            message:"删除成功"
                        })
                        this.load()
                    }
                    else{
                        this.$message({
                            type:"error",
                            message:res.msg
                        })
                    }
                    row.deleteBtnLoading=false

                })

            },
            handleSizeChange(pageSize){//改变当前每一页的个数触发
                this.pageSize=pageSize
                this.load()
            },
            handleCurrentChange(pageNum){//改变当前页码触发
                this.currentPage=pageNum
                this.load();
            },
            calldel(row){
                row.callBtnLoading=true
                request.post("/call",row).then(res =>{
                    if(res.code === 200){
                        this.$message({
                            type:"success",
                            message:"拨打成功"
                        })
                        this.load()
                    }
                    else{
                        this.$message({
                            type:"error",
                            message:res.msg,
                        })
                    }
                    row.callBtnLoading=false
                })


            },
            //时间戳转换
            getDate(date, fmt) {
                date =new Date(date)
                if (/(y+)/.test(fmt)) {
                    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
                }
                let o = {
                    'M+': date.getMonth() + 1,
                    'd+': date.getDate(),
                    'h+': date.getHours(),
                    'm+': date.getMinutes(),
                    's+': date.getSeconds()
                };
                for (let k in o) {
                    if (new RegExp(`(${k})`).test(fmt)) {
                        let str = o[k] + '';
                        fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : this.padLeftZero(str));
                    }
                }
                return fmt;
            },
            //时间戳转换（辅助）
            padLeftZero (str) {
                return ('00' + str).substr(str.length);
            },
        },
        components: {

        }
    }
</script>
